.headercontainer{
    color: white;
    font-family: 'Roboto', sans-serif;
}
.nav ul{
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    align-items: center;
    align-items: left;  
}

.listitem a{
    padding-top: 20px;
    text-decoration: none;
    color: white;
}

.listitem a:hover{
    transition: ease-in;
    border: 1px solid transparent;
    background-color: #4553FE;
    padding: 5px 7px 5px 7px;
    border-radius: 9px;
}

.sidepanel{
    display: none;
}

.openbtn {
display: none;
  }

  .closebtn {
  display: none;
  }


@media screen and (max-width: 768px){
    nav{
        display: none;
    }

    .sidepanel{
        display: block;
    }

    .sidepanel {
        height: 100%; /* Specify a height */
        width: 0; /* 0 width - change this with JavaScript */
        position: fixed; /* Stay in place */
        top: 0;
        left: 0;
        z-index: 1;
        background-color: #4553FE; /* Black*/
        overflow-x: hidden; /* Disable horizontal scroll */
        padding-top: 60px; /* Place content 60px from the top */
        transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
      }
      
      /* The sidepanel links */
      .sidepanel a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 15px;
        color: white;
        display: block;
        transition: 0.3s;
      }
      
  
        
      /* Style the button that is used to open the sidepanel */
      .openbtn { 
        display: block;
        font-size: 20px;
        cursor: pointer;
        background-color: #4553FE;
        color: white;
        padding: 10px 15px;
        border: none;
      }

      .closebtn {
      display: block;
      font-size: 20px;
      cursor: pointer;
      background-color: #4553FE;
      color: white;
      padding: 10px 15px;
      border: none;
      position: absolute;
      top: 0;
      right: 87%;
      font-size: 25px;
      margin-left: 50px;
      transition: 0.5s;
      z-index: 2;
      }
   
      
    
}