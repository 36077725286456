
.workcontainer{
  color: white;
  font-family: 'Roboto', sans-serif;
}

.topic{
  margin-left: 245px;
}

.topic p{
  width: 70%;
}

.note{
  margin-left: 211px;
  /* word-wrap: inherit; */
  text-align: center;
  width: 70%;
}

#test{
  display: none;
}

.project{
  width: 70%;
  text-align: center;
  margin-left: 211px;
}

.project iframe{
  width: 1000px;
  height: 500px;
}
.project img{
  width: 1000px;
  height: 700px;
}
 
@media screen and (max-width: 768px){

  .workcontainer{
    color: white;
    text-align: center;
    overflow-x: hidden;
  }
  
  .topic{
    margin-left: 10px;
  }
  

  .project{
    width: 70%;
    text-align: center;
    margin-left: 50px;
  }

  .project iframe{
    width: 300px;
    height: 200px;
    margin-right: 35px;
  }
  .project img{
    width: 300px;
    height: 200px;
    margin-right: 25px;
  }

  .topic{
    font-size: 10px;
    text-align: center;
    margin-left: 10px;
  }

  .note{
    text-align: center;
  }
   
}

@media screen and (max-device-width: 320px){

  .project{
    width: 70%;
    text-align: center;
    margin-left: 45px;
  }

  .project iframe{
    width: 200px;
    height: 100px;
  }
  .project img{
    width: 200px;
    height: 100px;
  }
  .project p{
    font-size: 12px;
  }
  .project h2{
    font-size: 17px;
  }

  .topic p{
    font-size: 10px;
    text-align: center;
    margin-left: 10px;
    width: 80%;
  }
}