.aboutcontainer{
    color: white;
}

.aboutimage{
    float: left;
    padding-right: 40px;
    margin-left: 120px;
  }
  .aboutimage img{
    border-radius: 20px;
  }

.aboutinfo{
    width: 90%;
    font-size: 25px;
    margin-left: 90px;
}

@media screen and (max-width: 1024px){
    .aboutinfo{
        font-size: 22px;
    }  

}
@media screen and (max-width: 768px){

    .aboutcontainer{
        margin-left: 110px;
    }
    .aboutinfo{
        font-size: 20px;
        width: 70%;
        text-align: center;
    }  
   
    .aboutimage{
        margin-left: 150px;
      }
}

@media screen and (max-width: 500px ){
    .aboutcontainer{
        margin-left: 0px;
        padding-right: 70px;
        overflow-x: hidden;
        font-family: 'Roboto', sans-serif;
    }
  
    .aboutimage{
        margin-left: 90px;
      }

    .aboutimage img{
        border-radius: 20px;
        width: 150px;
      }


    .aboutinfo{
        margin-right: 27px;
        width: 80%;
        font-size: 20px;
    } 
    .aboutinfo h1{
        text-align: center;
        font-size: 25px;
    } 
}