.contactcontainer{
color: white;
font-family: 'Roboto', sans-serif;
}

.contactheading{
    text-align: center;
}
.contactheading span{
   color: #4553FE;
}

.contactinfo{
    text-align: center;
    padding: 40px;
}
.contactinfo img{
    padding: 50px;
}
.contactinfo img:hover{
    transition: ease-in-out 1s;
    transform: scale(1.5);
}

@media screen and (max-width: 768px){
    .contactinfo img{
        padding: 15px;
        width: 40px;
    }

    .contactheading h1{
        font-size: 19px;
        font-family: 'Roboto', sans-serif;
    }

    #log1{
        width: 30px;
    }

  

    #log3{
        width: 30px;
    }
}
