body {
  background-color: #181818;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body::-webkit-scrollbar{
  width: 0.6em
 }

 body::-webkit-scrollbar-thumb{
   background-color: #4553FE;
   border-radius: 10px;
 }
