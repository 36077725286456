.container{
    color: white;
    text-align: center;
    margin: auto;
    width: 50%;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
}

.heading{
 font-size: 25px;
 font-family: 'Roboto', sans-serif;
}
.heading h3{
    font-size: 20px;
}

.dasboardbtn{
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    color: white;
    border: 1px solid white;
    padding: 17px 35px 17px 35px;
    border-radius: 9px;
}

 .dasboardbtn:hover{
 background-color: #4553FE;
}


.social{
padding-top: 45px;
}

.social img { 
 padding: 40px;  
 opacity: 0.3;
}

@keyframes zoominandout{
    0%{
        transform: scale(1, 1);
    }

    50%{
        transform: scale(1.1, 1.1);
    }
    100%{
        transform: scale(1, 1);
    }
}
.social img:hover{ 
 transition: ease-in-out 2s;
 animation: zoominandout 2s infinite;
opacity: 1;
}


@media screen and (max-width: 768px) {

    .container{
        width: 90%;
        padding-top: 40%;
        margin: auto;
    }
    
  
.heading h1{
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
   }

   .heading h3{
    font-size: 15px;
}

.dasboardbtn{
    text-decoration: none;
    text-align: center;
    font-size: 10px;
    color: white;
    border: 1px solid white;
    padding: 10px 25px 10px 25px;
    border-radius: 9px;
}

.social{
    padding-top: 100px;
    }
    
    .social img { 
     padding: 10px;  
     width: 40px;
    }
 
}

